
<div class="row container " >
  
    <main class="col-12">
        <h2 class="mt-1">Edit Patient</h2>
        <h3>Personal Details</h3>

        <div class="alert alert-info" *ngIf="createPatientForm.dirty">
            <p><strong>Information: </strong> Changes detected, please not any unsaved changes will be lost!</p>
        </div>
        
        <form [formGroup]="createPatientForm"  (ngSubmit)="addNewPatient()" >
            <div class="row">
                <div class="col-12">
                    <h4> Account Number: </h4>
                    <input type="text" class="form-control" name="accountNumber" formControlName="accountNumber" >
                </div>
                <div class="col-4">
                    <h4> First Name: </h4>
                    <input type="text" class="form-control" name="firstName" formControlName="firstName" >
                </div>
                <div class="col-4">
                    <h4> Last Name: </h4>
                    <input type="text" class="form-control" name="lastName" formControlName="lastName" >
                </div>
                <div class="col-4">
                    <h4> Title: </h4>
                    <input type="text" class="form-control" name="title" formControlName="title">
                </div>
              
            </div>
            <div class="row">
                
                <div class="col-4">
                    <h4> Middle Name: </h4>
                    <input type="text" class="form-control" name="middleName" formControlName="middleName" >
                </div>
                <div class="col-4">
                    <h4> Maiden Name: </h4>
                    <input type="text" class="form-control" name="maidenName" formControlName="maidenName" >
                </div>
                <div class="col-4">
                    <h4> Marital Status </h4>
                    <input type="text" class="form-control" name="maritalStatus" formControlName="maritalStatus">
                </div>
              
            </div>
            <div class="row">
                <div class="col-4">
                    <h4> Date Of Birth: </h4>
                    <input type="date" class="form-control" name="dateOfBirth" formControlName="dateOfBirth" >
                </div>
               
                <div class="col-4">
                    <h4> Gender: </h4>
                    <input type="text" class="form-control" name="gender" formControlName="gender">
                </div>
                <div class="col-4">
                    <h4> ID Number: </h4>
                    <input type="text" class="form-control" name="nationalIdentityNumber" formControlName="nationalIdentityNumber" >
                </div>
              
            </div>
            <div class="row mt-2">
                <div class="col-6">
                    <h4> Phone Number: </h4>
                    <input type="text" class="form-control" name="phone"  formControlName="phone">
                </div>
                <div class="col-6">
                    <h4> Email: </h4>
                    <input type="text" class="form-control" name="emailAddress" formControlName="emailAddress">
                </div>
                
              
            </div>
            <div class="row mt-2">
                <div class="col-4">
                    <h4> Passport Number: </h4>
                    <input type="text" class="form-control" name="passportNumber" formControlName="passportNumber" >
                </div>
                <div class="col-4">
                    <h4> Employer: </h4>
                    <input type="text" class="form-control" name="employer" formControlName="employer">
                </div>
                <div class="col-4">
                    <h4> Occupation: </h4>
                    <input type="text" class="form-control" name="occupation"  formControlName="occupation">
                </div>
              
            </div>



            <h4 class="mt-4">Medical and Psychological History</h4>
            <div class="row mt-2">
                <div class="col-4">
                    <h4>Illness History:</h4>
                    <input type="text" class="form-control" name="illnessHistory" formControlName="illnessHistory">
                </div>
                <div class="col-4">
                    <h4>Systematic Enquiry:</h4>
                    <input type="text" class="form-control" name="systematicEnquiry" formControlName="systematicEnquiry">
                </div>
                <div class="col-4">
                    <h4>Past Psychiatric History:</h4>
                    <input type="text" class="form-control" name="pastPsychiatricHistory" formControlName="pastPsychiatricHistory">
                </div>
            </div>
            
            <div class="row mt-2">
                <div class="col-6">
                    <h4>Medical History:</h4>
                    <input type="text" class="form-control" name="medicalHistory" formControlName="medicalHistory">
                </div>
                <div class="col-6">
                    <h4>Substance History:</h4>
                    <input type="text" class="form-control" name="substanceHistory" formControlName="substanceHistory">
                </div>
            </div>

            <h4 class="mt-4">Physical Address</h4>
            <div formGroupName="physicalAddress">
                <div class="row" ><!--STart of Physical Adddress-->
               
                    <div class="col-2">
                        <h4> Unit No: </h4>
                        <input type="number" class="form-control" name="unitNumber" formControlName="unitNumber" >
                    </div>
                    <div class="col-3">
                        <h4> Unit Name: </h4>
                        <input type="text" class="form-control" name="unitName" formControlName="unitName" >
                    </div>
                    <div class="col-4">
                        <h4> Street Address: </h4>
                        <input type="text" class="form-control" name="streetAddress" formControlName="streetAddress" >
                    </div>
                    <div class="col-3">
                        <h4> Postal Code </h4>
                        <input type="text" class="form-control" name="postalCode" formControlName="postalCode">
                    </div>
                  
                </div>
                <div class="row mt-2">
                   
                    <div class="col-3">
                        <h4> Optional Address Line: </h4>
                        <input type="text" class="form-control" name="addressLineTwo" formControlName="addressLineTwo" >
                    </div>
                    <div class="col-3">
                        <h4> Suburb: </h4>
                        <input type="text" class="form-control" name="suburb" formControlName="suburb">
                    </div>
                    <div class="col-3">
                        <h4> province: </h4>
                        <input type="text" class="form-control" name="province" formControlName="province">
                    </div>
                    <div class="col-3">
                        <h4> Country: </h4>
                        <input type="text" class="form-control" name="country" formControlName="country" >
                    </div>
                  
                </div><!--End of Physical Address-->
            </div>
            
            <div formGroupName="medicalAid">
                <h3 class="mt-2"> Medical Aid Details</h3>
                <div class="row ">
                   
                    <div class="col-4">
                        <h4> Medical Aid Provider: </h4>
                        <input type="text" class="form-control" name="medicalAidName" formControlName="medicalAidName" >
                    </div>
                    <div class="col-4">
                        <h4> Medical Aid No: </h4>
                        <input type="text" class="form-control" name="medicalAidNumber" formControlName="medicalAidNumber" >
                    </div>
                    <div class="col-4">
                        <h4> Dependent Code: </h4>
                        <input type="text" class="form-control" name="dependentCode" formControlName="dependentCode" >
                    </div>
                    <div class="col-4">
                        <h4> Main Member: </h4>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="medicalAidMainMember" value="true" id="flexRadioDefault1" 
                             formControlName="medicalAidMainMember">
                            <label class="form-check-label" for="flexRadioDefault1">
                              Yes
                            </label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="radio" name="medicalAidMainMember" value="false" id="flexRadioDefault2" 
                            formControlName="medicalAidMainMember">
                            <label class="form-check-label" for="flexRadioDefault2">
                              No
                            </label>
                          </div>
                    </div>
                    
                  
                </div>
                <div> <!-- fill in form for none holder patients-->
            
                    <div class="row mt-3">
                   
                        <div class="col-4">
                            <h4> Holder Full Name: </h4>
                            <input type="text" class="form-control" name="medicalAidMainMemberFullName" formControlName="medicalAidMainMemberFullName" >
                        </div>
                        <div class="col-4">
                            <h4>Holder ID No: </h4>
                            <input type="text" class="form-control" name="medicalAindMainMemberIdentityNumber" formControlName="medicalAindMainMemberIdentityNumber" >
                        </div>
                        <div class="col-4">
                            <h4> Occupation: </h4>
                            <input type="text" class="form-control" name="occupation" formControlName="occupation">
                        </div>
                        
                    </div>
                    <div class="row mt-3">
                      
                        <div class="col-6">
                            <h4>Holder Physical Address: </h4>
                            <input type="text" class="form-control" name="residentaillAddress" formControlName="residentaillAddress">
                        </div>
                        <div class="col-6">
                            <h4> Holder Occupation: </h4>
                            <input type="text" class="form-control" name="postalAddress" formControlName="postalAddress">
                        </div>
                        
                      
                    </div>
                    <div class="row">
                       
                        <div class="col-4">
                            <h4> Holder Employer : </h4>
                            <input type="text" class="form-control" name="employer"  formControlName="employer">
                        </div>
                        <div class="col-4">
                            <h4>Holder Phone: </h4>
                            <input type="text" class="form-control" name="phoneNumber" formControlName="phoneNumber" >
                        </div>
                        <div class="col-4">
                            <h4>Holder Email: </h4>
                            <input type="text" class="form-control" name="postalAddress" formControlName="postalAddress" >
                        </div>
                     
                    </div>
                
            
                </div><!--End of medical holder details-->
            </div>

            <h4 class="mt-4">Next Of Kin Details</h4>
            <div formGroupName="patientNextOfKin">
                <div class="row" ><!--Next of Kin-->
               
                    <div class="col-4">
                        <h4> Full Name: </h4>
                        <input type="text" class="form-control" name="fullName" formControlName="fullName" >
                    </div>
                    <div class="col-4">
                        <h4> Email Address: </h4>
                        <input type="text" class="form-control" name="emailAddress" formControlName="emailAddress" >
                    </div>
                    <div class="col-4">
                        <h4> Phone Number: </h4>
                        <input type="text" class="form-control" name="phoneNumber" formControlName="phoneNumber" >
                    </div>
                  
                  
                </div>
                <div class="row mt-2">
                   
                    <div class="col-7">
                        <h4> Full Address: </h4>
                        <input type="text" class="form-control" name="fullAddress" formControlName="fullAddress" >
                    </div>
                    <div class="col-5">
                        <h4> Relationship: </h4>
                        <input type="text" class="form-control" name="relationship" formControlName="relationship">
                    </div>
                </div><!--Next of Kin-->
            </div>
            <h4 class="mt-4">REF Doctor Details</h4>
            <div formGroupName="patientRefDoctor">
                <div class="row" ><!--Ref Doctor-->
               
                    <div class="col-4">
                        <h4> Full Name: </h4>
                        <input type="text" class="form-control" name="fullName" formControlName="fullName" >
                    </div>
                    <div class="col-4">
                        <h4> Email Address: </h4>
                        <input type="text" class="form-control" name="emailAddress" formControlName="emailAddress" >
                    </div>
                   
                </div>
                <div class="row mt-2">
                    <div class="col-5">
                        <h4> Practice Number: </h4>
                        <input type="text" class="form-control" name="practiceNumber" formControlName="practiceNumber">
                    </div>
                    <div class="col-7">
                        <h4> Full Address: </h4>
                        <input type="text" class="form-control" name="fullAddress" formControlName="fullAddress" >
                    </div>
                   
                </div><!--Ref Doctor-->

               
</div>




                <h4 class="mt-4">Employment History</h4>
<div formGroupName="employmentHistory">
    <div class="row">
        <div class="col-4">
            <h4>Current Job:</h4>
            <input type="text" class="form-control" name="current" formControlName="current">
        </div>
        <div class="col-4">
            <h4>Last Job:</h4>
            <input type="text" class="form-control" name="lastJob" formControlName="lastJob">
        </div>
        <div class="col-4">
            <h4>Previous Jobs:</h4>
            <input type="text" class="form-control" name="previousJobs" formControlName="previousJobs">
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-6">
            <h4>Reason for Leaving Each Job:</h4>
            <input type="text" class="form-control" name="whyLeaveEachJob" formControlName="whyLeaveEachJob">
        </div>
        <div class="col-6">
            <h4>On Disability Grant (DG) - Why:</h4>
            <input type="text" class="form-control" name="onDGWhy" formControlName="onDGWhy">
        </div>
    </div>
</div>

<h4 class="mt-4">Family History</h4>
<div formGroupName="familyHistory">
    <div class="row">
        <div class="col-3">
            <h4>Father:</h4>
            <input type="text" class="form-control" name="father" formControlName="father">
        </div>
        <div class="col-3">
            <h4>Mother:</h4>
            <input type="text" class="form-control" name="mother" formControlName="mother">
        </div>
        <div class="col-3">
            <h4>Brother:</h4>
            <input type="text" class="form-control" name="brother" formControlName="brother">
        </div>
        <div class="col-3">
            <h4>Sister:</h4>
            <input type="text" class="form-control" name="sister" formControlName="sister">
        </div>
    </div>
</div>

<h4 class="mt-4">Relationship History</h4>
<div formGroupName="relationshipHistory">
    <div class="row">
        <div class="col-4">
            <h4>Marital Status:</h4>
            <input type="text" class="form-control" name="maritalStatus" formControlName="maritalStatus">
        </div>
        <div class="col-4">
            <h4>Number of Children:</h4>
            <input type="number" class="form-control" name="numberOfChildren" formControlName="numberOfChildren">
        </div>
        <div class="col-4">
            <h4>Sexually Active:</h4>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="sexuallyActive" [value]="true" formControlName="sexuallyActive">
                <label class="form-check-label">Yes</label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="sexuallyActive" [value]="false" formControlName="sexuallyActive">
                <label class="form-check-label">No</label>
            </div>
        </div>
    </div>
</div>

<h4 class="mt-4">Personal History</h4>
<div formGroupName="personalHistory">
    <div class="row">
        <div class="col-6">
            <h4>Birth and Early Childhood Development:</h4>
            <input type="text" class="form-control" name="birthAndECD" formControlName="birthAndECD">
        </div>
        <div class="col-6">
            <h4>Present and Placement:</h4>
            <input type="text" class="form-control" name="presentAndPlacement" formControlName="presentAndPlacement">
        </div>
    </div>
</div>




         
           
        <button  class="btn btn-primary mt-3" [disabled]="!createPatientForm.dirty"> Save Changes</button>
        
        </form>
        <!--p>{{createPatientForm.value|json}}</p-->
    </main>
    </div>
