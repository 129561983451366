import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Patient } from 'src/app/interfaces/patient';
import { PatientRecord } from 'src/app/interfaces/patient-record';


import { PatientRecordService } from 'src/app/_services/patient-record.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DatePipe } from '@angular/common';
import { AdminService } from 'src/app/_services/admin.service';
import { Staff } from 'src/app/interfaces/staff';
import { Pagination } from 'src/app/interfaces/pagination';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-patient-file',
  templateUrl: './patient-file.component.html',
  styleUrls: ['./patient-file.component.css']
})
export class PatientFileComponent implements OnInit {
  //public patientRecord:Patient ;
  public pagination:Pagination;
  @Input() patient:Patient;
  createPatientRecordForm:FormGroup;
  createPatientForm:FormGroup;
  records:FormArray;
  selectedRecord?:PatientRecord;
  public patientRecords:PatientRecord[] =[];
  pageNumber?:number =1;
  pageSize?:number =1;
  SearchTerm?:string='';
  doctors:Staff[];
 
  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.createPatientRecordForm.reset();
    this.modalRef = this.modalService.show(template,{class: 'modal-lg'});
    
    
  }
  /*openPatientModal(patientTemplate: TemplateRef<any>) {
    this.modalRef = this.modalService.show(patientTemplate);
  }*/
 hideModal(){
 
  
  //this.createPatientRecordForm.reset();
  //console.log('closed modla man')
  this.modalRef.hide();

 
 }
 openModalRecord(updateTemplate: TemplateRef<any>,patientRecord:PatientRecord){
  this.modalRef = this.modalService.show(updateTemplate,{class: 'modal-lg'});
  this.selectedRecord=patientRecord;
  this.createPatientRecordForm.patchValue({
    
    'assignedDoctor_Id':this.selectedRecord.assignedDoctor_Id,
  'co':this.selectedRecord.co,
  'adlNeurovegFxn':this.selectedRecord.adlNeurovegFxn,
  'dateVisited': this.datePipe.transform(this.selectedRecord.dateVisited, 'yyyy-MM-dd'),
  'behaviorSomatic':this.selectedRecord.behaviorSomatic,
  'cognitiveFxn': this.selectedRecord.cognitiveFxn,
        'mood': this.selectedRecord.mood,
        'msce': this.selectedRecord.msce,
        'physical': this.selectedRecord.physical,
        'diagnosis': this.selectedRecord.diagnosis,
        'management': this.selectedRecord.management,
        'plan': this.selectedRecord.plan

})
  
 }
 

  constructor(private patientRecordService: PatientRecordService,private adminService:AdminService, private formBuilder:FormBuilder, 
    private modalService:BsModalService, private toastService:ToastrService,
    private datePipe:DatePipe) { }
  

  ngOnInit(): void {
    this.getPatientRecords();
    this.initializeCreatePatientRecordForm();
    this.loadDoctors();
  
    
  }

  initializeCreatePatientRecordForm(){
    this.createPatientRecordForm= new FormGroup(
      {
        assignedDoctor_Id:new FormControl(),
        co: new FormControl(),
        adlNeurovegFxn: new FormControl(),
        behaviorSomatic: new FormControl(),
        cognitiveFxn: new FormControl(),
        mood: new FormControl(),
        msce: new FormControl(),
        physical: new FormControl(),
        diagnosis: new FormControl(),
        management: new FormControl(),
        plan: new FormControl(),
        dateVisited:new FormControl()
       
      }
    )
  }

  /*newPatient(){
    this.patientRecordService.createPatientRecord(this.patient.patientID ,this.createPatientForm.value).subscribe(response=>{
      this.toastService.success('Your Doctor Refereral has been successfully created.', 'Success')
    },error=>{
      this.toastService.error(error.error);
      
    })
  }*/
 
  newPatientRecord(){
    
    this.patientRecordService.createPatientRecord(this.patient.patientID,
      this.createPatientRecordForm.value).subscribe(response=>{
        this.toastService.success('Your Record been successfully created.', 'Success');
        this.getPatientRecords();
        this.modalRef.hide();
      
      },error=>{
        this.toastService.error(error.error);
        
      });
   }
   updatePatientRecord(){
    this.patientRecordService.updatePatientRecord(
      this.selectedRecord.patientRecordID,this.createPatientRecordForm.value).subscribe(()=>{
        this.toastService.success('Patient Record Updated successfully','Patiemt Record Update');
        this.getPatientRecords();
        this.modalRef.hide();
        //this.createPatientRecordForm.reset();
        
      },error=>{
        this.toastService.error(error.error);
        
      });
  }


getPatientRecords(){
  this.patientRecordService.getPatientRecords(this.patient.patientID,this.pageNumber,
    this.pageSize,this.SearchTerm).subscribe(response=>{
      this.patientRecords=response.result;
      this.pagination=response.pagination;
    })
}
pageChanged(event){
  this.pageNumber=event.page;
  this.getPatientRecords();
}

loadDoctors(){
  this.adminService.getDoctors('doctor').subscribe(doctorsResponse=>{
    this.doctors=doctorsResponse;
   
  },error=>{
    this.toastService.error(error);
    
  })
}

// Generate image for the pdf
getBase64ImageFromURL(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = error => {
      reject(error);
    };
    img.src = url;
  });
}

// Generation the pdf

async generatePdf(record:PatientRecord){
  this.selectedRecord=record;
  const documentDefinition = {
  content: [

   
 
 
  //logo
  {
    image: await this.getBase64ImageFromURL(
      "assets/molifelogo.png"
    ),width:100,
    alignment : 'center'
   },
   { text: 'Patient Basic Details ', style: 'header2' },
  
   
   
    {text:'MHCA Status: BHC DEGREE IN USE AND GERMANY'+ '\n'},
   
    {
      style:'hr',
      columns: [
        
        {text:'Email: molefie@gmail.com '+ '\n'},
        {text:'Tel: 074 334 6707  '+ '\n'},
      ]
    },
    {text: ' '+ '\n'},
 
   {

  
  
    columns: [
    
       [
        {text: 'Account Number : '+ this.patient.accountNumber,style:'header2'},
        //heading
           {text:'Patient ID # '+ this.patient.nationalIdentityNumber,marginBottom:10},
        
           
           {text: 'Date created: '+ this.datePipe.transform(this.selectedRecord.dateCreated) + '\n'} 
       ]
   
     
    ]

  },

  //#### Table personal Details#####
  {
    style:'table',
    table:{
      widths: ['*', '*', '*'],  // Ensure uniform width for each column
      body:[
        [{text:'Patient Details',bold:true,alignment:'center',colSpan:3},{},{}],

        [{text:[{text:'Full Name: ',style:'header2'},this.patient.firstName+' '+this.patient.lastName]},
      {text:[{text:'Title: ',style:'bold'},this.patient.title]}, 
      {text:[{text:'D.O.B: ',style:'bold'},this.datePipe.transform(this.patient.dateOfBirth,'yyyy-MM-dd')]}],


      [{text:[{text:'Gender: ',style:'bold'},this.patient.gender]},
      {text:[{text:'ID No: ',style:'bold'},this.patient.nationalIdentityNumber]},
       {text:[{text:'Passport Number: ',style:'bold'},this.patient.passportNumber]}],
      [{text:[{text:'Employer: ',style:'bold'},this.patient.employer]},
      {text:[{text:'Occupation: ',style:'bold'},this.patient.occupation]},
      {text:[{text:'Phone Number: ',style:'bold'},this.patient.phone]}],

      [{text:[{text:'Email: ',style:'bold'}, this.patient.emailAddress],colSpan:2},{},{}],
      [{text:[{text:'Address: ',style:'bold'}, this.patient.physicalAddress.unitNumber + ' '
      +this.patient.physicalAddress.unitName + ', '+this.patient.physicalAddress.streetAddress
    +', '+this.patient.physicalAddress.suburb +', '+this.patient.physicalAddress.province +', '
  +this.patient.physicalAddress.country +','+this.patient.physicalAddress.postalCode ],colSpan:3}],

    //#### Table Medical Details#####
  [{text:'Medical Aid Details',style:'header2',alignment:'center',colSpan:3},{},{}],
  [{text:[{text:'Provider: ',style:'bold'},this.patient.medicalAid.medicalAidName]},

  {text:[{text:'Medical Aid No : ',style:'bold'},this.patient.medicalAid.medicalAidNumber]}, 
  {text:[{text:'Main Member: ',style:'bold'},this.patient.medicalAid.medicalAidMainMember==true?'YES':'NO']}],
  
  [{text:[{text:'Main Member Details : ',style:'bold'}],colSpan:3},{},{}],

  [{text:[{text:'Holder Name : ',style:'bold'},this.patient.medicalAid.medicalAidMainMemberFullName]},
  {text:[{text:'Holder ID No: ',style:'bold'},this.patient.medicalAid.medicalAindMainMemberIdentityNumber]},
  {text:[{text:'Holder Occupation: ',style:'bold'},this.patient.medicalAid.occupation]}],

  [{text:[{text:'Phone Number : ',style:'bold'}, this.patient.medicalAid.phoneNumber],colSpan:2},
  {text:[{text:'Employer : ',style:'bold'}, this.patient.medicalAid.employer==null?'NA':this.patient.medicalAid.employer]},{}],
  [{text:[{text:'Address: ',style:'bold'}, this.patient.medicalAid.residentaillAddress ],colSpan:3}],

        //#### Record Details#####
    [{text:'Medical Record Details',style:'header2',alignment:'center',colSpan:3},{},{}],//row1

    [{text:[{text:'Review Date: ',style:'bold',alignment:'center'}, this.datePipe.transform(this.selectedRecord.dateVisited,'yyyy-MM-dd')],colSpan:3}, 
      {},{}], //row2

    [{text:[{text:'C/O: ',style:'bold'}, ]}, 
      {text:this.selectedRecord.co,colSpan:2},{}], //row3

    [{text:[{text:'ADL & NEUROVEG FXN: ',style:'bold'}, ]}, 
      {text:this.selectedRecord.adlNeurovegFxn, colSpan:2},{}], //row4
    
    [{text:[{text:'BEHAVIOUR/SOMATIC: ',style:'bold'}, ]}, 
      {text:this.selectedRecord.behaviorSomatic,colSpan:2},{}], //row5

    [{text:[{text:'COGNITIVE FXN: ',style:'bold'}],}, 
      {text:this.selectedRecord.cognitiveFxn,colSpan:2},{}], //row6

    [{text:[{text:'MOOD: ',style:'bold'}, ]}, 
      {text:this.selectedRecord.mood,colSpan:2},{}], //row7

    [{text:[{text:'MSCE: ',style:'bold'}, ]}, 
      {text:this.selectedRecord.msce,colSpan:2},{}], //row8

    [{text:[{text:'PHYSICAL: ',style:'bold'}, ]}, 
      {text:this.selectedRecord.physical,colSpan:2},{}], //row9

    [{text:[{text:'DIAGNOSIS: ',style:'bold'},]}, 
      {text: this.selectedRecord.diagnosis,colSpan:2},{}], //row10

    [{text:[{text:'MANAGEMENT: ',style:'bold'}, ]}, 
      {text:this.selectedRecord.management,colSpan:2},{}], //row11

    [{text:[{text:'PLAN: ',style:'bold'}, ]}, 
      {text:this.selectedRecord.plan,colSpan:2},{}], //row10





 
    ] //body
    }//table
  }, // Patient Details
  {
    text:'Confidential Information',style:'private'
  },
  {
    text:'Private Details and other details here.....'
  }
  
  

  ],
  styles:{
    header1:{
      fontSize:18,
      bold:true,
      marginBottom:10
    },
    header2:{
      fontSize:15,
      bold:true,
      marginTop:10
    },
    private:{
      fontSize:13,
      bold:true,
      marginTop:20,
      color:'red'
    },
    hr:{
      marginTop:10,
      borderTop:2,
      marginBottom:10

    },
    total:{
      fontSize:15,
      bold:true,
      marginTop:10,
      color:'red'
    },
    mr:{
      marginRight:10
    },
    bold:{
      bold:true
    }
  }
};
  pdfMake.createPdf(documentDefinition).open();
 }

}
