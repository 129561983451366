import { Injectable } from '@angular/core';
import { Assessment } from '../interfaces/assessment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PaginatedResult } from '../interfaces/pagination';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {
  baseurl:string= environment.baseUrl;
  patient:Assessment;
  paginatedResult:PaginatedResult<Assessment[]>= new PaginatedResult<Assessment[]>();
    
    constructor(private http:HttpClient) { }
    getAssessments(patientId:number,pageNumber?:number,pageSize?:number,SearchTerm?:string){
      let params = new HttpParams();
      if(SearchTerm!==null){
        params=params.append('searchTerm',SearchTerm)
  
      }
      if (pageNumber!==null && pageSize !==null){
        params=params.append('pageNumber', pageNumber.toString());
        params=params.append('pageSize', pageSize.toString());
      
      }
      return this.http.get<Assessment[]>(this.baseurl +'patient-assessments/patientId/'+patientId,{observe:'response',params}).pipe(
        map(response=>{
          this.paginatedResult.result=response.body;
          if(response.headers.get('pagination')!==null){
            this.paginatedResult.pagination=JSON.parse(response.headers.get('pagination'));
          }
         // console.log(JSON.parse(response.headers.get('pagination')));
          return this.paginatedResult;
        })
      )
    }
  
    getAssessmentById(assessmentId:number){
      return this.http.get<Assessment>(this.baseurl + 'patient-assessments/'+assessmentId )
    }
  
    createAssessment( patientId:number,assessment:any){
      return this.http.post<Assessment>(this.baseurl + 'patient-assessments/'+patientId, assessment);
      
    }
  
    updateAssessment(assessmentID:number,assessment:any){
      return this.http.put(this.baseurl+'patient-assessments/'+assessmentID, assessment  );
    }
  
    
  }
  