import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtModule } from "@auth0/angular-jwt";
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import{BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { NavComponent } from './header/nav/nav.component';
import { LoginComponent } from './login/login.component';
import { DesignationsComponent } from './designations/designations.component';
import { HomeComponent } from './pages/home/home.component';
import { DoctorListComponent } from './pages/doctor-list/doctor-list.component';
import { DoctorDetailComponent } from './pages/doctor-list/doctor-detail/doctor-detail.component';
import { PatientListComponent } from './pages/patient-list/patient-list.component';
import { PatientDetailComponent } from './pages/patient-list/patient-detail/patient-detail.component';
import { SidenavComponent } from './pages/shared/sidenav/sidenav.component';
import { PatientEditComponent } from './pages/patient-list/patient-detail/patient-edit/patient-edit.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingInterceptor } from './_interceptors/loading.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { PatientNewComponent } from './pages/patient-new/patient-new.component';
import { InvoicesComponent } from './pages/patient-list/patient-detail/invoices/invoices.component';
import { PatientFileComponent } from './pages/patient-list/patient-detail/patient-file/patient-file.component';
import { from } from 'rxjs';
import { MyChartComponent } from './my-chart/my-chart.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { DoctorNewComponent } from './pages/doctor-new/doctor-new.component';
import { DoctorEditComponent } from './pages/doctor-list/doctor-detail/doctor-edit/doctor-edit.component';
import { MyTaskListComponent } from './pages/my-task-list/my-task-list.component';
import { DatePipe } from '@angular/common';
import { StaffListComponent } from './pages/staff-list/staff-list.component';
import { StaffDetailComponent } from './pages/staff-list/staff-detail/staff-detail.component';
import { StaffEditComponent } from './pages/staff-list/staff-edit/staff-edit.component';
import { StaffNewComponent } from './pages/staff-new/staff-new.component';
import { AppointmentListComponent } from './pages/appointment-list/appointment-list.component';
import { PaymentListComponent } from './pages/payment-list/payment-list.component';
import { InvoiceListComponent } from './pages/invoice-list/invoice-list.component';
import { UserRegistrationComponent } from './users/user-registration/user-registration.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { ErrorInterceptor } from './_interceptors/error.interceptor';
import { appInitializer } from './_interceptors/appInitializer';
import { AuthenticationService } from './_services/authentication.service';
import { PatientAssessmentComponent } from './pages/patient-list/patient-detail/patient-assessment/patient-assessment.component';


export function tokenGetter() {
  return localStorage.getItem("mytoken");
}


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavComponent,
    LoginComponent,
    DesignationsComponent,
    HomeComponent,
    DoctorListComponent,
    DoctorDetailComponent,
    PatientListComponent,
    PatientDetailComponent,
    SidenavComponent,
    PatientEditComponent,
    PatientNewComponent,
    InvoicesComponent,
    PatientFileComponent,
    MyChartComponent,
    DoctorNewComponent,
    DoctorEditComponent,
    MyTaskListComponent,
    StaffListComponent,
    StaffDetailComponent,
    StaffEditComponent,
    StaffNewComponent,
    AppointmentListComponent,
    PaymentListComponent,
    InvoiceListComponent,
    UserRegistrationComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UserListComponent,
    PatientAssessmentComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
    ToastrModule.forRoot({
      positionClass:'toast-bottom-right'
    }),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:5000","localhost:5001"],
        disallowedRoutes: []
      }
    })
  ],
  //{ provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
  providers: [
  {provide:HTTP_INTERCEPTORS,useClass:LoadingInterceptor,multi:true},
    {provide: HTTP_INTERCEPTORS,useClass:JwtInterceptor,multi:true},
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { 
  
}
