<div *ngIf="patient">

    <div class="table-responsive">
    
        <h4 class="mt-5 col" >Assessment Records</h4> 
                   
        <hr>
        <button type="button" class="btn btn-primary mb-2" (click)="openModal(template)">Add New Patient assessment</button>
        <table class="table table-striped table-sm">
          <thead>
           
          <tbody>
              <div  *ngFor= "let assessment of assessments"  >
                <tr>
                  <th>Assessment ID #</th>
                <td>{{assessment.assessmentID}}</td>
                </tr>
                <tr>
                    <th> Source of Referal</th>
                  <td>{{assessment.referral}}</td>
                  </tr>

                  <tr>
                    <th> Reason of Referal</th>
                  <td>{{assessment.reasonForReferral}}</td>
                  </tr>

                  <tr>
                    <th> Date of Assessment</th>
                  <td>{{assessment.dateCreated |date: 'short'}}</td>
                  </tr>
               
               <td>
                  <button type="button" class="btn-outline-dark"  (click)='openModalRecord(template,assessment)'>Edit</button>
                  <button (click)="generatePdf(assessment)" class="btn-outline-dark"> View / Print</button>
                </td>
        
              
              
              </div>
              
          </tbody>
      </table>
      </div>

      <div class="d-flex justify-content-center" *ngIf="pagination">
        <pagination [boundaryLinks]="true"
         [totalItems]="pagination.totalCount"
         [itemsPerPage]="pagination.pageSize"
         [(ngModel)]="pagination.currentPage"
         (pageChanged)="pageChanged($event)"
            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
        </pagination>
    </div>
    
</div>

 
<ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Create Patient Assessment Record</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
    
        <form [formGroup]="createAssessmentForm" (ngSubmit)="onSubmit()">
            <h3>{{ isEditMode ? 'Edit Assessment' : 'Add New Assessment' }}</h3>
            <a class="btn btn-danger mt-1 mb-2" (click)="resetForm()"> Cancel</a>
           <!-- DG Select Input -->
<hr>
            <div class="mb-3 mt-2">
                <label for="dg" class="form-label me-2">DG  :</label>
                <select id="dg" formControlName="dg" class="form-select ">
                   
                <option *ngFor="let option of dgOptions" [value]="option.value">{{ option.label }}</option>
                </select>
            </div>

            <!-- Dynamic Textarea Fields -->
            <div *ngFor="let data of textareaFieldsData" class="mb-3">
              <label [for]="data.field" class="form-label">{{ data.label | titlecase}}</label>
              <textarea [id]="data.field" formControlName="{{ data.field }}" class="form-control" rows="3"></textarea>
            </div>
            

            <button type="submit" class="btn btn-primary" [disabled]="!createAssessmentForm.dirty"> {{ isEditMode ? 'Update' : 'Add' }}</button>
          </form>
          
    </div>
  </ng-template> <!--End of Create Invoice template-->

  


 



  
  
