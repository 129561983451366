<div class="row container mt-1" *ngIf="patient" >
  
 
    <main class="col-12">
      <div class="heading"> 
        <h2>Patient Details</h2>
      </div>
        <div>
            <tabset>
              <tab heading="Basic Information" id="tab1" >
               

                <h4 class="mt-5 form-inline my-2" >Patient Personal Details</h4> 
                <button (click)="generatePdf()" class="btn-outline-dark" >Download PDF </button>
                <button (click)="generatePdf()" class="btn-outline-dark"> Print File</button>
                
                
                <hr>
                <table class="table table-bordered">
                  
                  <tbody>
                    <tr>
                      <td><label for="patientAccountNumber" class="fw-bold">Account Number :</label> {{"  " + patient.accountNumber!==null? patient.accountNumber:'...'}}</td>
                    </tr>
                    <tr>
                      <td><label for="patient" class="fw-bold">Title :</label> {{"  " + patient.title!==null? patient.title:'...'}}</td>
                      <td colspan="0.5"><label for="patient" class="fw-bold">Surname :</label> {{"  "+ patient.lastName}}</td>
                      <td colspan="0.5" *ngIf="maiden"><label for="patient" class="fw-bold">Maiden Name :</label> {{"  "+ patient.maidenName}}</td>
                      <td colspan="0.5" ><label for="patient" class="fw-bold">First Name :</label> {{"  "+ patient.firstName}}</td>
                    </tr>
                    
                    <tr>
                     
                      <td colspan="0.5" ><label for="patient" class="fw-bold">Marital Status:</label> {{"  "+ patient.maritalStatus}}</td>
                      <td><label for="patient" class="fw-bold">D.O.B : </label>{{"   " +patient.dateOfBirth |date: '  dd/MM/yyyy'}}</td>
                      <td ><label for="patient" class="fw-bold"> ID No :  </label>{{"   " +patient.nationalIdentityNumber}}</td>
                      <td ><label for="patient" class="fw-bold">Gender :  </label>{{"   " +patient.gender}}</td>
                      
                      <!--td ><label for="patient" class="fw-bold">Passport :  </label>{{"   " +patient.passportNumber!==null? patient.passportNumber:'' | uppercase}}</td-->
                    </tr>
                    <tr>
                      <td colspan="2"><label for="patient" class="fw-bold"> Employer :  </label>{{"   " +patient.employer}}</td>
                      <td ><label for="patient" class="fw-bold">Occupation :  </label>{{"   " +patient.occupation}}</td>
                    </tr>
                    <tr>
                      <td colspan="2"><label for="patient" class="fw-bold"> Physical Address:  </label>{{"   " +patient.physicalAddress.unitNumber
                         + ' '+ patient.physicalAddress.unitName + ',  ' + patient.physicalAddress.streetAddress + ',  '+ patient.physicalAddress.addressLineTwo
                        + ',  '+ patient.physicalAddress.province + ',  '+ patient.physicalAddress.country}}</td>
                      <td ><label for="patient" class="fw-bold">Postal Code :  </label>{{"   " +patient.physicalAddress.postalCode}}</td>
                    </tr>
                    <tr>
                      <td ><label for="patient" class="fw-bold"> Contact Number :  </label>{{"   " +patient.phone}}</td>
                      <td colspan="2"><label for="patient" class="fw-bold">Email :  </label>{{"   " +patient.emailAddress}}</td>
                     
                    </tr>
                    
                  </tbody>
                </table><!--end of first table basic information-->

                <!--Start of Second Table Medical AID Records-->
                <h3 class="mt-5">Medical Aid </h3>
                <h4 class="">Principa Holder Details </h4>
                <hr>
                <table class="table table-bordered">
                  
                  <tbody>
                    <tr >
                    
                      <td ><label for="patient" class="fw-bold">Medical Aid Provider :</label> {{"  "+ patient.medicalAid.medicalAidName}}</td>
                 
                      <td><label for="patient" class="fw-bold">Medical Aid No :</label> {{"  " + patient.medicalAid.medicalAidNumber}}</td>
                      <td><label for="patient" class="fw-bold"> Dependent Code:</label> {{"  " + patient.medicalAid.dependentCode}}</td>
                    </tr>
                    <tr>
                      <td *ngIf="!patient.medicalAid.medicalAidMainMember"><label for="patient" class="fw-bold">Main Member :  </label>No </td>
                      <td *ngIf="patient.medicalAid.medicalAidMainMember"><label for="patient" class="fw-bold">Main Member :  </label>YES </td>
                      
                    </tr>
                   

                      <tr  *ngIf="!patient.medicalAid.medicalAidMainMember" >
                        <td colspan="2"><label for="patient" class="fw-bold"> Main Member Details :  </label></td>
                        <td ><label for="patient" class="fw-bold">Holder Name :  </label>{{"   " +patient.medicalAid.medicalAidMainMemberFullName}}</td>
                      </tr>
                      <tr  *ngIf="!patient.medicalAid.medicalAidMainMember">
                        <td colspan="1"><label for="patient" class="fw-bold">Holder ID No:  </label>{{"   " +patient.medicalAid.medicalAindMainMemberIdentityNumber}}</td>
                        <td ><label for="patient" class="fw-bold">Holder Occupation :  </label>{{"   " +patient.medicalAid.occupation}}</td>
                        <td ><label for="patient" class="fw-bold">Holder Employer :  </label>{{"   " +patient.medicalAid.employer}}</td>
                      </tr>
                      <tr  *ngIf="!patient.medicalAid.medicalAidMainMember">
                        <td colspan=""><label for="patient" class="fw-bold">Physical Addresss:  </label>{{"   " +patient.medicalAid.residentaillAddress}}</td>
                        <td ><label for="patient" class="fw-bold">Postal Address:  </label>{{"   " +patient.medicalAid.postalAddress}}</td>
                        <td ><label for="patient" class="fw-bold">Phone Number :  </label>{{"   " +patient.medicalAid.phoneNumber}}</td>
                      </tr>

                    <!--If its main main holder--> 
                  </tbody>
                </table>
                 <!--Start of Next of kin Details-->
                 <h4 class="mt-5">Patient's Next Of Kin Details </h4>
                
                 <hr>
                 <table class="table table-bordered">
                   
                   <tbody>
                     <tr >
                     
                       <td colspan="2"><label for="patient" class="fw-bold">Full Name :</label> {{"  "+ patient.patientNextOfKin.fullName}}</td>
                  
                       <td><label for="patient" class="fw-bold">Email Address :</label> {{"  " + patient.patientNextOfKin.emailAddress}}</td>
                      </tr>
                     <tr>
                       <td  colspan="1.5"><label for="patient" class="fw-bold"> Relationship :</label> {{" " + patient.patientNextOfKin.relationship}}</td>
                       <td colspan="1.5"><label for="patient" class="fw-bold">Phone Number :</label> {{"  "+ patient.patientNextOfKin.phoneNumber}}</td>
                  
                     </tr>
 
                     <tr>
                       <td colspan="3"><label for="patient" class="fw-bold"><Address></Address> Physical Address:</label> {{"  " + patient.patientNextOfKin.fullAddress}}</td>
                     </tr>
                    
                     <!--end of next of kin Details-->
                    
                     
                   </tbody>
                 </table>
                  <!--Start of Ref Doctor Details-->
                  <h4 class="mt-5">Referring  Doctor Details </h4>
                
                  <hr>
                  <table class="table table-bordered">
                    
                    <tbody>
                      <tr >
                      
                        <td colspan="2"><label for="patient" class="fw-bold">Full Name :</label> {{"  "+ patient.patientRefDoctor.fullName}}</td>
                   
                        <td><label for="patient" class="fw-bold">Email Address :</label> {{"  " +patient.patientRefDoctor.emailAddress}}</td>
                       </tr>
                      <tr>
                        <td  colspan="1"><label for="patient" class="fw-bold"> Practice Number :</label> {{" " + patient.patientRefDoctor.practiceNumber}}</td>
                        <td colspan="2"><label for="patient" class="fw-bold">Address :</label> {{"  "+ patient.patientRefDoctor.fullAddress}}</td>
                   
                      </tr>
  
                     
                     
                      <!--end of ref doc Details-->
                     
                      
                    </tbody>
                  </table>
                 <button (click)="generatePdf()">Download PDF File</button>
              </tab>
             
               

              <tab heading="Edit Records"><app-patient-edit [patient]="patient"></app-patient-edit></tab>
              <tab heading="Patient Review Form"><app-patient-file [patient]="patient" ></app-patient-file></tab>
              <tab heading="Patient Assessments"><app-patient-assessment [patient]="patient" ></app-patient-assessment></tab>
              <tab heading="Invoices"><app-invoices [patient]="patient"></app-invoices></tab>
            </tabset>
          </div>

    </main>
  </div>