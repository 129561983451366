import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Patient } from 'src/app/interfaces/patient';
import { PatientRecord } from 'src/app/interfaces/patient-record';
import { Person } from 'src/app/interfaces/person';
import { PatientRecordService } from 'src/app/_services/patient-record.service';
import { PatientService } from 'src/app/_services/patient.service';
import { PersonService } from 'src/app/_services/person.service';

@Component({
  selector: 'app-patient-edit',
  templateUrl: './patient-edit.component.html',
  styleUrls: ['./patient-edit.component.css']
})
export class PatientEditComponent implements OnInit {

  @HostListener ('window:beforeunload',['$event']) unloadNotification($event:any){
    if(this.createPatientForm.dirty){
      $event.returnValue=true;
    }
  }

  createPatientForm:FormGroup;
  @Input() patient:Patient;
  
  constructor(private router:Router, private patientService:PatientService,private toastService:ToastrService, private fb:FormBuilder) { }
 
  initilizePatientForm(){
   
    
    this.createPatientForm = this.fb.group({
      accountNumber: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      middleName: new FormControl(),
      maidenName: new FormControl(),
      title: new FormControl(),
      maritalStatus: new FormControl(),
      dateOfBirth: new FormControl(),
      gender: new FormControl(),
      nationalIdentityNumber: new FormControl(),
      passportNumber: new FormControl(),
      phone: new FormControl(),
      emailAddress: new FormControl(),
      occupation: new FormControl(),
      employer: new FormControl(),
      illnessHistory: new FormControl(),
      systematicEnquiry: new FormControl(),
      pastPsychatricHistory: new FormControl(),
      medicalHistory: new FormControl(),
      subStanceHistory: new FormControl(),

    
      physicalAddress: this.fb.group({
        unitNumber: [0],
        unitName: new FormControl(),
        streetAddress: new FormControl(),
        addressLineTwo: new FormControl(),
        postalCode: new FormControl(),
        suburb: new FormControl(),
        province: new FormControl(),
        country: new FormControl()
      }),
    
      medicalAid: this.fb.group({
        medicalAidName: new FormControl(),
        medicalAidNumber: new FormControl(),
        medicalAidMainMember: new FormControl(),
        medicalAidMainMemberFullName: new FormControl(),
        medicalAindMainMemberIdentityNumber: new FormControl(),
        dependentCode: new FormControl(),
        occupation: new FormControl(),
        postalAddress: new FormControl(),
        employer: new FormControl(),
        residentialAddress: new FormControl(),
        phoneNumber: new FormControl()
      }),
    
      patientNextOfKin: this.fb.group({
        fullName: new FormControl(),
        emailAddress: new FormControl(),
        phoneNumber: new FormControl(),
        fullAddress: new FormControl(),
        relationship: new FormControl()
      }),
    
      patientRefDoctor: this.fb.group({
        name: new FormControl(),
        fullName: new FormControl(),
        practiceNumber: new FormControl(),
        emailAddress: new FormControl(),
        fullAddress: new FormControl()
      }),
    
      employmentHistory: this.fb.group({
        current: new FormControl(),
        lastJob: new FormControl(),
        previousJobs: new FormControl(),
        whyLeaveEachJob: new FormControl(),
        hasEverWorked:[false],
        onDGWhy: new FormControl()
      }),
    
      personalHistory: this.fb.group({
        birthAndECD: new FormControl(), // early childhood development
        presentAndPlacement: new FormControl()
      }),
    
      relationshipHistory: this.fb.group({
        maritalStatus: new FormControl(),
        numberOfChildren: [0],
        numberOfPartners: [0],
        inARelationship: new FormControl(),
        howLongTested: new FormControl(), // how long has the relationship lasted
        previousRelationships: new FormControl(),
        sexuallyActive: ['false']
      }),
    
      familyHistory: this.fb.group({
        father: new FormControl(),
        mother: new FormControl(),
        brother: new FormControl(),
        sister: new FormControl(),
        others: new FormControl(),
      })
    });
    
  }

    setPatientFormValue(){
     // console.log(this.patient);
      this.createPatientForm.patchValue( this.patient);

    }

 

  ngOnInit(): void {
    this.initilizePatientForm();
    this.setPatientFormValue();
  }
  updatePatient(){
    //console.log(this.createPatientForm.value);
   this.patientService.updatePatient(this.patient.patientID,this.createPatientForm.value).subscribe(()=>{
    this.toastService.success('Your Patient been successfully updated.', 'Success');
    this.createPatientForm.reset();
    setTimeout(()=>{
      window.location.reload();
    },3000);
    
    //console.log(this.createPatientForm.dirty);
    },error=>{
      this.toastService.error(error);
      
   })
  // console.log(this.createPatientForm.value);
  }
  
  

}
//console.log(this.patientEdit.value);