import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import pdfMake from 'pdfmake/build/pdfmake';
import { AdminService } from 'src/app/_services/admin.service';
import { AssessmentService } from 'src/app/_services/assessment.service';
import { Assessment } from 'src/app/interfaces/assessment';
import { Pagination } from 'src/app/interfaces/pagination';
import { Patient } from 'src/app/interfaces/patient';
import { Staff } from 'src/app/interfaces/staff';

@Component({
  selector: 'app-patient-assessment',
  templateUrl: './patient-assessment.component.html',
  styleUrls: ['./patient-assessment.component.css']
})
export class PatientAssessmentComponent implements OnInit {

 
  //public assessment:Patient ;
  public pagination:Pagination;
  @Input() patient:Patient;
  createAssessmentForm:FormGroup;
  createPatientForm:FormGroup;
  records:FormArray;
  selectedRecord?:Assessment;
  public assessments:Assessment[] =[];
  assessment?:Assessment;
  pageNumber?:number =1;
  pageSize?:number =1;
  SearchTerm?:string='';
  doctors:Staff[];
 
  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.createAssessmentForm.reset();
    this.modalRef = this.modalService.show(template,{class: 'modal-lg'});
    
    
  }
  /*openPatientModal(patientTemplate: TemplateRef<any>) {
    this.modalRef = this.modalService.show(patientTemplate);
  }*/
 hideModal(){
 
  
  //this.createAssessmentForm.reset();
  //console.log('closed modla man')
  this.modalRef.hide();

 
 }
 openModalRecord(template: TemplateRef<any>,assessment:Assessment){
  this.modalRef = this.modalService.show(template,{class: 'modal-lg'});
  this.selectedRecord=assessment;
  this.currentAssessmentID= this.selectedRecord.assessmentID;
  this.isEditMode=true;
  this.createAssessmentForm.patchValue( this.selectedRecord);
  
 }
 

  constructor(private assessmentService: AssessmentService,private adminService:AdminService, private formBuilder:FormBuilder, 
    private modalService:BsModalService, private toastService:ToastrService,
    private datePipe:DatePipe) { }
  

  ngOnInit(): void {
    this.getAssessments();
    this.initializeCreateAssessmentForm();
    this.loadDoctors();
  
    
  }

  initializeCreateAssessmentForm(){
    this.createAssessmentForm= this.formBuilder.group(
      {
       
        dg: [false],
      referral: [''],
      reasonForReferral: [''],
      collateralInfo: [''],
      schooling: [''],
      premorbidPersonality: [''],
      supportSystems: [''],
      forensicHistory: [''],
      peGeneral: [''],
      pecvs: [''],
      peResp: [''],
      peAbd: [''],
      pecns: [''],
      mseAppeaance: [''],
      mseDress: [''],
      mseBehaviour: [''],
      mseSpeech: [''],
      msePsychomotorActivity: [''],
      thoughtForm: [''],
      thoughtContent: [''],
      affect: [''],
      mood: [''],
      vegetativeSymptoms: [''],
      perceptualDistrubances: [''],
      cogConciousness: [''],
      cogOrientation: [''],
      cogAttention: [''],
      cogConcerntration: [''],
      cogMemory: [''],
      cogGeneralKnowledge: [''],
      cogIntelligence: [''],
      cogJudgement: [''],
      cogInsight: [''],
      summaryOfComplaints: [''],
      summaryOfMSE: [''],
      summaryOfPE: [''],
      summaryOfBio: [''],
      summaryPsycho: [''],
      summarySocial: [''],
      differanitalDiagnosis: [''],
      finalDiagnosis: [''],
      problemList: [''],
      riskAssessment: [''],
      haematological: [''],
      radiological: [''],
      invetsigationsOther: [''],
      prognosisFactors: [''],
      factors: [''],
      sickNote: [''],
      treatment: ['']
       
      }
    );
  }
  isEditMode = false;

  textareaFields: string[] = [
    'referral', 'reasonForReferral', 'collateralInfo', 'schooling', 'premorbidPersonality',
    'supportSystems', 'forensicHistory', 'peGeneral', 'pecvs', 'peResp', 'peAbd', 'pecns',
    'mseAppeaance', 'mseDress', 'mseBehaviour', 'mseSpeech', 'msePsychomotorActivity', 
    'thoughtForm', 'thoughtContent', 'affect', 'mood', 'vegetativeSymptoms', 
    'perceptualDistrubances', 'cogConciousness', 'cogOrientation', 'cogAttention', 
    'cogConcerntration', 'cogMemory', 'cogGeneralKnowledge', 'cogIntelligence', 'cogJudgement', 
    'cogInsight', 'summaryOfComplaints', 'summaryOfMSE', 'summaryOfPE', 'summaryOfBio', 
    'summaryPsycho', 'summarySocial', 'differanitalDiagnosis', 'finalDiagnosis', 'problemList', 
    'riskAssessment', 'haematological', 'radiological', 'invetsigationsOther', 'prognosisFactors', 
    'factors', 'sickNote', 'treatment'
  ];

  textareaFieldsLabels: string[] = [
    'SOURCE OF REFERAL', 'REASON FOR REFERAL', 'COLLATERAL INFORMATION', 'schooling', 'PREMORBID PERSONALITY',
    'SUPPORT SYSTEMS', 'FORENSIC HISTORY', ' PHSYSICALEXAMINATION : General', 'PHSYSICALEXAMINATION : C.V.S', 'PHSYSICALEXAMINATION : Resp', 'PHSYSICALEXAMINATION : Abd', 'PHSYSICALEXAMINATION : C.N.S',
    'MENTA STATE EXAMINATION : Appeaance', 'MENTA STATE EXAMINATION : Dress', 'MENTA STATE EXAMINATION : Behaviour', 'MENTA STATE EXAMINATION : Speech', 'MENTA STATE EXAMINATION : Psychomotor Activity', 
    'Thought Form', 'Thought Content', 'affect', 'mood', 'vegetative Symptoms', 
    'perceptual Distrubances', 'COGNITIONS : Level of Conciousness', 'COGNITIONS : Orientation', 'COGNITIONS : Attention', 
    'COGNITIONS : Concerntration', 'COGNITIONS : Memory', 'COGNITIONS : General Knowledge', 'COGNITIONS : Intelligence', 'COGNITIONS : Judgement', 
    'COGNITIONS : Insight', 'FORMULATION : Summary Of Complaints', 'FORMULATION : Summary Of MSE', 'FORMULATION : Summary Of Physical Examination', 'SUMMARY OF CRITICAL BIO-SOCIAL FACTORS (INCLUDING PSYSHODYNAMICS) : Bio', 
    'SUMMARY OF CRITICAL BIO-SOCIAL FACTORS (INCLUDING PSYSHODYNAMICS) : Psycho', 'SUMMARY OF CRITICAL BIO-SOCIAL FACTORS (INCLUDING PSYSHODYNAMICS) : Social', 'differanital Diagnosis', 'final Diagnosis', 'problem List', 
    'risk Assessment', 'INVESTIGATIONS : haematological', 'INVESTIGATIONS : radiological', 'invetsigations : Other', 'Prognosis (+) Factors', 
    'Factors (-)', 'sick Note', 'treatment'
  ];

  textareaFieldsData = this.textareaFields.map((field, index) => ({
    field: field,
    label: this.textareaFieldsLabels[index]
  }));
  
  dgOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ];
  currentAssessmentID: number | null = null;

  onSubmit(): void {

    const formValues = this.createAssessmentForm.value;
    if (this.isEditMode && this.currentAssessmentID) {
      // Update mode
      const updateAssessment: Assessment = {
        assessmentID: this.currentAssessmentID,
        patientID:this.patient.patientID,
        ...formValues
      };
      this.assessmentService.updateAssessment(this.selectedRecord.assessmentID,updateAssessment).subscribe(() => {
        this.getAssessments();
       
        this.resetForm();
        alert('Record updated successfully!');
      }, (error) => {
        alert('Error updating! Opps!');
      });
    } else {
      // Add mode
      const newAssessment: Assessment = {
        patientID:this.patient.patientID,
        ...formValues
      };
  
      this.assessmentService.createAssessment(this.patient.patientID,newAssessment).subscribe(() => {
        this.getAssessments();
        this.resetForm();
        alert('Record added successfully!');
      }, (error) => {
        alert('Error updating! Opps!');
      });
    }
  }

  resetForm(): void {
    this.modalRef.hide();
    this.createAssessmentForm.reset();
    this.isEditMode = false;
    this.currentAssessmentID = null;
  }



getAssessments(){
  this.assessmentService.getAssessments(this.patient.patientID,this.pageNumber,
    this.pageSize,this.SearchTerm).subscribe(response=>{
      this.assessments=response.result;
      console.log(this.assessments);
      this.pagination=response.pagination;
    })
}
pageChanged(event){
  this.pageNumber=event.page;
  this.getAssessments();
}

loadDoctors(){
  this.adminService.getDoctors('doctor').subscribe(doctorsResponse=>{
    this.doctors=doctorsResponse;
   
  },error=>{
    this.toastService.error(error);
    
  })
}

// Generate image for the pdf
getBase64ImageFromURL(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = error => {
      reject(error);
    };
    img.src = url;
  });
}

// Generation the pdf


  async generatePdf(record:Assessment) {
    this.assessment=record;
    const documentDefinition = {
      content: [
      //logo
    {
      image: await this.getBase64ImageFromURL(
        "assets/molifelogo.png"
      ),width:100,
      alignment : 'center'
     },
     { text: 'PSYCHIATRIC ASSESSMENT ', style: 'header2' },
    
     
     
      {text:'MHCA Status: BHC DEGREE IN USE AND GERMANY'+ '\n'},
     
      {
        style:'hr',
        columns: [
          
          {text:'Email: molefie@gmail.com '+ '\n'},
          {text:'Tel: 074 334 6707  '+ '\n'},
        ]
      },
      {text: ' '+ '\n'},
     
    
 
 
  {
    style:'hr',
    columns: [
      
   
      {text: 'Date of assessment: '+ this.datePipe.transform(this.assessment.dateCreated) + '\n'}
   
    ]
  },
  
        // Patient Header
        {
          columns: [
            [
             
             
              { text: 'Patient Account #:  ' + this.patient.accountNumber, marginBottom: 10,bold: true }
            ]
          ]
        },
  
        // Personal Details Table
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'IDENTIFICATION DATA',style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: [{ text: 'Title: ', style: 'bold' }, this.patient.title] },
                { text: [{ text: 'Surname: ', style: 'bold' }, this.patient.lastName] },
                { text: [{ text: 'Name: ', style: 'bold' }, this.patient.firstName + ' ' + (this.patient.middleName || '')] },
              ],
              [
                
                { text: [{ text: 'Gender: ', style: 'bold' }, this.patient.gender] },
                { text: [{ text: 'D.O.B: ', style: 'bold' }, this.datePipe.transform(this.patient.dateOfBirth, 'yyyy-MM-dd')] },
                { text: [{ text: 'DG: ', style: 'bold' }, this.assessment.dg==false?'No':'Yes'] }
              ],
              [
                { text: [{ text: 'Phone Number: ', style: 'bold' }, this.patient.phone] },
                { text: [{ text: 'ID No: ', style: 'bold' }, this.patient.nationalIdentityNumber], colSpan: 2 }, {}
              ],
              
              [
                { text: [{ text: 'Occupation: ', style: 'bold' }, this.patient.occupation] },
                { text: [{ text: 'Email: ', style: 'bold' }, this.patient.emailAddress], colSpan: 2 }, {}
              ],
              [
                {
                  text: [
                    { text: 'Address: ', style: 'bold' },
                    `${this.patient.physicalAddress.unitNumber} ${this.patient.physicalAddress.unitName}, ${this.patient.physicalAddress.streetAddress}, ${this.patient.physicalAddress.suburb}, ${this.patient.physicalAddress.province || ''}, ${this.patient.physicalAddress.country || ''}, ${this.patient.physicalAddress.postalCode}`
                  ],
                  colSpan: 3
                },
                {}, {}
              ]
              ,
              [
                {
                  text: [
                    { text: 'Source of Referral: ', style: 'bold' },
                    this.assessment.referral
                  ],
                  colSpan: 3
                },
                {}, {}
              ]
              ,
              [
                {
                  text: [
                    { text: 'Reason for Referral: ', style: 'bold' },
                    this.assessment.reasonForReferral
                  ],
                  colSpan: 3
                },
                {}, {}
              ]
            ]
          }
        },
  
       /* // Medical Aid Details
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'Medical Aid Details', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: [{ text: 'Provider: ', style: 'bold' }, this.patient.medicalAid.medicalAidName] },
                { text: [{ text: 'Medical Aid No : ', style: 'bold' }, this.patient.medicalAid.medicalAidNumber] },
                { text: [{ text: 'Main Member: ', style: 'bold' }, this.patient.medicalAid.medicalAidMainMember ? 'YES' : 'NO'] }
              ],
              [
                { text: [{ text: 'Dependent Code : ', style: 'bold' }, this.patient.medicalAid.dependentCode], colSpan: 3 }, {}, {}
              ],
              [
                { text: [{ text: 'Main Member Details : ', style: 'bold' }], colSpan: 3 }, {}, {}
              ],
              [
                { text: [{ text: 'Holder Name : ', style: 'bold' }, this.patient.medicalAid.medicalAidMainMemberFullName || 'NA'] },
                { text: [{ text: 'Holder ID No: ', style: 'bold' }, this.patient.medicalAid.medicalAindMainMemberIdentityNumber || 'NA'] },
                { text: [{ text: 'Holder Occupation: ', style: 'bold' }, this.patient.medicalAid.occupation || 'NA'] }
              ],
              [
                { text: [{ text: 'Phone Number : ', style: 'bold' }, this.patient.medicalAid.phoneNumber || 'NA'], colSpan: 2 },
                { text: [{ text: 'Employer : ', style: 'bold' }, this.patient.medicalAid.employer || 'NA'] }, {}
              ],
              [
                { text: [{ text: 'Address: ', style: 'bold' }, this.patient.medicalAid.residentaillAddress || 'NA'], colSpan: 3 }, {}, {}
              ]
            ]
          }
        },*/
  
        // Next of Kin Details
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'Next Of Kin Details', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: [{ text: 'Full Name: ', style: 'bold' }, this.patient.patientNextOfKin.fullName] },
                { text: [{ text: 'Email Address : ', style: 'bold' }, this.patient.patientNextOfKin.emailAddress] },
                { text: [{ text: 'Phone Number: ', style: 'bold' }, this.patient.patientNextOfKin.phoneNumber] }
              ],
              [
                { text: [{ text: 'Relationship : ', style: 'bold' }, this.patient.patientNextOfKin.relationship], colSpan: 2 }, {}, {}
              ],
              [
                { text: [{ text: 'Address: ', style: 'bold' }, this.patient.patientNextOfKin.fullAddress], colSpan: 3 }, {}, {}
              ]
            ]
          }
        },
  
        /*// Referring Doctor Details
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'Referring Doctor Details', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: [{ text: 'Full Name: ', style: 'bold' }, this.patient.patientRefDoctor.fullName] },
                { text: [{ text: 'Email Address : ', style: 'bold' }, this.patient.patientRefDoctor.emailAddress] },
                { text: [{ text: 'Practice Number: ', style: 'bold' }, this.patient.patientRefDoctor.practiceNumber] }
              ],
              [
                { text: [{ text: 'Address: ', style: 'bold' }, this.patient.patientRefDoctor.fullAddress], colSpan: 3 }, {}, {}
              ]
            ]
          }
        },*/

         // Assessment Details Table
      
         {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'HISTORY OF PRESENTING ILLLNESS', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: this.patient.illnessHistory, alignment: 'center', colSpan: 3 }, {}, {}
              ],
            ]
          }
        },
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'SYSTEMIC ENQUIRY', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: this.patient.systematicEnquiry, alignment: 'center', colSpan: 3 }, {}, {}
              ],
            ]
          }
        },
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'PAST PSYCHIATRIC HISTORY', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: this.patient.pastPsychatricHistory, alignment: 'center', colSpan: 3 }, {}, {}
              ],
            ]
          }
        },
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'CURRENT + PAST MEDICAL/ SURGICAL etc. HISTORY', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: this.patient.medicalHistory, alignment: 'center', colSpan: 3 }, {}, {}
              ],
            ]
          }
        },
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'CURRENT AND PAST SUBSTANCE HISTORY', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: this.patient.subStanceHistory, alignment: 'center', colSpan: 3 }, {}, {}
              ],
            ]
          }
        },

        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'COLLATERAL INFORMATION', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: this.assessment.collateralInfo, alignment: 'center', colSpan: 3 }, {}, {}
              ],
            ]
          }
        },
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'FAMILY HISTORY', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: 'Father', alignment: 'center' },
                { text: this.patient.familyHistory.father, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Mother', alignment: 'center' },
                { text: this.patient.familyHistory.mother, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Brother', alignment: 'center' },
                { text: this.patient.familyHistory.brother, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Sister', alignment: 'center' },
                { text: this.patient.familyHistory.sister, alignment: 'center', colSpan: 2 }, {}
              ], 
               [
                { text: 'Others (grandparents, uncles, aunt cousins)', alignment: 'center' },
                { text: this.patient.familyHistory.others, alignment: 'center', colSpan: 2 }, {}
              ],
            ]
          }
        },

        // Personal history
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'PERSONAL HISTORY', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: 'Birth and Early Development', alignment: 'center' },
                { text: this.patient.personalHistory.birthAndECD, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Present and placement (by parents / grandparents / aunts etc.', alignment: 'center' },
                { text: this.patient.personalHistory.presentAndPlacement, alignment: 'center', colSpan: 2 }, {}
              ],
             
            ]
          }
        },
        //schooling
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'SCHOOLING', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: this.assessment.schooling, alignment: 'center', colSpan: 3 }, {}, {}
              ],
            ]
          }
        },

        // Employment history
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'EMPLOYMENT HISTORY', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: 'current', alignment: 'center' },
                { text: this.patient.employmentHistory.current, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Last Job', alignment: 'center' },
                { text: this.patient.employmentHistory.lastJob, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Previous Jobs', alignment: 'center' },
                { text: this.patient.employmentHistory.previousJobs, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Why did patient leave each job?', alignment: 'center' },
                { text: this.patient.employmentHistory.whyLeaveEachJob, alignment: 'center', colSpan: 2 }, {}
              ], 
               [
                { text: 'Has patient ever worked?', alignment: 'center' },
                { text: this.patient.employmentHistory.hasEverWorked, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'If unemployed, is patient on D.G What for?', alignment: 'center' },
                { text: this.patient.employmentHistory.onDGWhy, alignment: 'center', colSpan: 2 }, {}
              ],
            ]
          }
        },

         // relationship history
         {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'RELATIONSHIP HISTORY', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: 'Marital Status', alignment: 'center' },
                { text: this.patient.relationshipHistory.maritalStatus, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'No. of Children', alignment: 'center', colSpan: 2  },
                { text: this.patient.relationshipHistory.numberOfPartners, alignment: 'center'}, {}
              ],
              [
                { text: 'No. of partners', alignment: 'center', colSpan: 2  },
                { text: this.patient.relationshipHistory.numberOfPartners, alignment: 'center'}, {}
              ],
              [
                { text: 'Is patient in a r/ship?', alignment: 'center' },
                { text: this.patient.relationshipHistory.inARelationship, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'How long has it tested', alignment: 'center' },
                { text: this.patient.relationshipHistory.howLongTested, alignment: 'center', colSpan: 2 }, {}
              ], 
               [
                { text: 'Previous r/ships', alignment: 'center' },
                { text: this.patient.relationshipHistory.previousRelationships, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Is patient sexually active', alignment: 'center' },
                { text: this.patient.relationshipHistory.sexuallyActive, alignment: 'center', colSpan: 2 }, {}
              ],
            ]
          }
        },

          //PREMORBID PERSONALITY
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],  // Ensure uniform width for each column
              body: [
                [{ text: 'PREMORBID PERSONALITY', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
                [
                  { text: this.assessment.premorbidPersonality, alignment: 'center', colSpan: 3 }, {}, {}
                ],
              ]
            }
          },

           //SUPPORT SYSTEMS
           {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],  // Ensure uniform width for each column
              body: [
                [{ text: 'SUPPORT SYSTEMS', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
                [
                  { text: this.assessment.supportSystems, alignment: 'center', colSpan: 3 }, {}, {}
                ],
              ]
            }
          },

           //RELIGION AND CULTURAL BELIEFS
           {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],  // Ensure uniform width for each column
              body: [
                [{ text: 'RELIGION AND CULTURAL BELIEFS', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
                [
                  { text: this.patient.religion, alignment: 'center', colSpan: 3 }, {}, {}
                ],
              ]
            }
          },

           //FORENSIC HISTORY
           {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],  // Ensure uniform width for each column
              body: [
                [{ text: 'FORENSIC HISTORY', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
                [
                  { text: this.assessment.forensicHistory, alignment: 'center', colSpan: 3 }, {}, {}
                ],
              ]
            }
          },

          // PHSYSICALEXAMINATION
         {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'PHSYSICALEXAMINATION', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: 'General', alignment: 'center' },
                { text: this.assessment.peGeneral, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'C.V.S', alignment: 'center' },
                { text: this.assessment.pecvs, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Resp', alignment: 'center' },
                { text: this.assessment.peResp, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Abd', alignment: 'center' },
                { text: this.assessment.peAbd, alignment: 'center', colSpan: 2 }, {}
              ], 
              [
                { text: 'C.N.S', alignment: 'center' },
                { text: this.assessment.pecns, alignment: 'center', colSpan: 2 }, {}
              ], 
            ]
          }
        },

         // MENTA STATE EXAMINATION
         {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'MENTA STATE EXAMINATION', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: 'Appearance', alignment: 'center' },
                { text: this.assessment.mseAppeaance, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Dress', alignment: 'center' },
                { text: this.assessment.mseDress, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Behaviour', alignment: 'center' },
                { text: this.assessment.mseBehaviour, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Speech', alignment: 'center' },
                { text: this.assessment.mseSpeech, alignment: 'center', colSpan: 2 }, {}
              ], 
              [
                { text: 'Psychomotor activity', alignment: 'center' },
                { text: this.assessment.msePsychomotorActivity, alignment: 'center', colSpan: 2 }, {}
              ], 
            ]
          }
        },

           //THOUGHT FORM
           {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],  // Ensure uniform width for each column
              body: [
                [{ text: 'THOUGHT FORM', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
                [
                  { text: this.assessment.thoughtForm, alignment: 'center', colSpan: 3 }, {}, {}
                ],
              ]
            }
          },

            //THOUGH CONTENT
            {
              style: 'table',
              table: {
                widths: ['*', '*', '*'],  // Ensure uniform width for each column
                body: [
                  [{ text: 'THOUGH CONTENT', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
                  [
                    { text: 'Affect', alignment: 'center' },
                    { text: this.assessment.affect, alignment: 'center', colSpan: 2 }, {}
                  ],
                  [
                    { text: 'Mood', alignment: 'center' },
                    { text: this.assessment.mood, alignment: 'center', colSpan: 2 }, {}
                  ],
                  [
                    { text: 'Vegetative symptoms', alignment: 'center' },
                    { text: this.assessment.vegetativeSymptoms, alignment: 'center', colSpan: 2 }, {}
                  ],
                  [
                    { text: 'Perceptual disturbances', alignment: 'center' },
                    { text: this.assessment.perceptualDistrubances, alignment: 'center', colSpan: 2 }, {}
                  ], 
                ]
              }
            },

               //COGNITIONS
               {
                style: 'table',
                table: {
                  widths: ['*', '*', '*'],  // Ensure uniform width for each column
                  body: [
                    [{ text: 'COGNITIONS', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
                    [
                      { text: 'Level of Consciousness', alignment: 'center' },
                      { text: this.assessment.cogConciousness, alignment: 'center', colSpan: 2 }, {}
                    ],
                    [
                      { text: 'Orientation', alignment: 'center' },
                      { text: this.assessment.cogOrientation, alignment: 'center', colSpan: 2 }, {}
                    ],
                    [
                      { text: 'Attention', alignment: 'center' },
                      { text: this.assessment.cogAttention, alignment: 'center', colSpan: 2 }, {}
                    ],
                    [
                      { text: 'Concentration', alignment: 'center' },
                      { text: this.assessment.cogConcerntration, alignment: 'center', colSpan: 2 }, {}
                    ], 

                    [
                      { text: 'Memory', alignment: 'center' },
                      { text: this.assessment.cogMemory, alignment: 'center', colSpan: 2 }, {}
                    ], 
                    [
                      { text: 'General knowledge', alignment: 'center' },
                      { text: this.assessment.cogGeneralKnowledge, alignment: 'center', colSpan: 2 }, {}
                    ],  [
                      { text: 'Intelligence', alignment: 'center' },
                      { text: this.assessment.cogIntelligence, alignment: 'center', colSpan: 2 }, {}
                    ],  [
                      { text: 'Judgement', alignment: 'center' },
                      { text: this.assessment.cogJudgement, alignment: 'center', colSpan: 2 }, {}
                    ],  [
                      { text: 'Insight', alignment: 'center' },
                      { text: this.assessment.cogInsight, alignment: 'center', colSpan: 2 }, {}
                    ], 
                  ]
                }
              },

        //FORMULATION
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'SUMMARY OF CRITICAL BIO-SOCIAL FACTORS (INCLUDING PSYSHODYNAMICS)', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: 'Bio', alignment: 'center' },
                { text: this.assessment.summaryOfBio, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Psycho', alignment: 'center' },
                { text: this.assessment.summaryPsycho, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Social', alignment: 'center' },
                { text: this.assessment.summarySocial, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Prognosis (+) Factors', alignment: 'center' },
                { text: this.assessment.prognosisFactors, alignment: 'center', colSpan: 2 }, {}
              ],
              [
                { text: 'Factors (-)', alignment: 'center' },
                { text: this.assessment.summarySocial, alignment: 'center', colSpan: 2 }, {}
              ], 
            ]
          }
        },

         //DIFFERANITAL DIAGNOSIS
         {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'DIFFERANITAL DIAGNOSIS', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: this.assessment.differanitalDiagnosis, alignment: 'center', colSpan: 3 }, {}, {}
              ],
            ]
          }
        },

          //FINAL DIAGNOSIS
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],  // Ensure uniform width for each column
              body: [
                [{ text: 'FINAL DIAGNOSIS', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
                [
                  { text: this.assessment.finalDiagnosis, alignment: 'center', colSpan: 3 }, {}, {}
                ],
              ]
            }
          },

      //PROBLEM LIST
      {
        style: 'table',
        table: {
          widths: ['*', '*', '*'],  // Ensure uniform width for each column
          body: [
            [{ text: 'PROBLEM LIST', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
            [
              { text: this.assessment.problemList, alignment: 'center', colSpan: 3 }, {}, {}
            ],
          ]
        }
      },

         //RISK ASSESSMENT
         {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],  // Ensure uniform width for each column
            body: [
              [{ text: 'RISK ASSESSMENT', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
              [
                { text: this.assessment.riskAssessment, alignment: 'center', colSpan: 3 }, {}, {}
              ],
            ]
          }
        },

     //INVESTIGATIONS
     {
      style: 'table',
      table: {
        widths: ['*', '*', '*'],  // Ensure uniform width for each column
        body: [
          [{ text: 'INVESTIGATIONS', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
          [
            { text: 'Haematological', alignment: 'center' },
            { text: this.assessment.haematological, alignment: 'center', colSpan: 2 }, {}
          ],
          [
            { text: 'Radiological', alignment: 'center' },
            { text: this.assessment.radiological, alignment: 'center', colSpan: 2 }, {}
          ],
          [
            { text: 'Other', alignment: 'center' },
            { text: this.assessment.invetsigationsOther, alignment: 'center', colSpan: 2 }, {}
          ], 
        ]
      }
    },

      //TREATMENT
      {
        style: 'table',
        table: {
          widths: ['*', '*', '*'],  // Ensure uniform width for each column
          body: [
            [{ text: 'TREATMENT', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
            [
              { text: this.assessment.treatment, alignment: 'center', colSpan: 3 }, {}, {}
            ],
          ]
        }
      },


      //SICK NOTE
      {
        style: 'table',
        table: {
          widths: ['*', '*', '*'],  // Ensure uniform width for each column
          body: [
            [{ text: 'SICK NOTE', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
            [
              { text: this.assessment.sickNote, alignment: 'center', colSpan: 3 }, {}, {}
            ],

            [
              { text: [{ text: 'Date: ', style: 'bold' }, ''] },
              { text: [{ text: 'Signature: ', style: 'bold' }, ''], colSpan: 2 }, {}
            ],

          ]
        }
      },

      {
        style: 'table',
        table: {
          widths: ['*', '*', '*'],  // Ensure uniform width for each column
          body: [
            [{ text: 'Assessment Details', style: 'header2', alignment: 'center', colSpan: 3 }, {}, {}],
        
            // Add other rows for remaining assessment fields
            [
              { text: [{ text: 'Date Created: ', style: 'bold' }, this.datePipe.transform(this.assessment.dateCreated, 'yyyy-MM-dd')] },
              { text: [{ text: 'Date Updated: ', style: 'bold' }, this.datePipe.transform(this.assessment.dateUpdated, 'yyyy-MM-dd')] },
              {}
            ]
          ]
        }

      }
        

      ],
      styles: {
        header1: { fontSize: 15, bold: true, marginBottom: 10 },
        header2: { fontSize: 13, bold: true, marginTop: 10 },
        bold: { bold: true }
      }
    };
  
    pdfMake.createPdf(documentDefinition).open();
  }
  

}
