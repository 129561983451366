import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Patient } from 'src/app/interfaces/patient';
import { Person } from 'src/app/interfaces/person';
import { PatientService } from 'src/app/_services/patient.service';

import { PersonService } from 'src/app/_services/person.service';

const personType:string='patient';

@Component({
  selector: 'app-patient-new',
  templateUrl: './patient-new.component.html',
  styleUrls: ['./patient-new.component.css']
})
export class PatientNewComponent implements OnInit {
  @ViewChild('patientAdd') patientAdd:NgForm;
  @HostListener ('window:beforeunload',['$event']) unloadNotification($event:any){
    if(this.patientAdd.dirty){
      $event.returnValue=true;
    }
  }
  createPatientForm:FormGroup;
patient:Patient;


  constructor(private toastService:ToastrService,private patientService:PatientService,private route:Router, private fb:FormBuilder) { }

  ngOnInit(): void {
    this.initilizePatientForm();
  }

  initilizePatientForm(){
  
    
    this.createPatientForm = this.fb.group({
      accountNumber: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      middleName: new FormControl(),
      maidenName: new FormControl(),
      title: new FormControl(),
      maritalStatus: new FormControl(),
      dateOfBirth: new FormControl(),
      gender: new FormControl(),
      nationalIdentityNumber: new FormControl(),
      passportNumber: new FormControl(),
      phone: new FormControl(),
      emailAddress: new FormControl(),
      occupation: new FormControl(),
      employer: new FormControl(),
      illnessHistory: new FormControl(),
      systematicEnquiry: new FormControl(),
      pastPsychatricHistory: new FormControl(),
      medicalHistory: new FormControl(),
      subStanceHistory: new FormControl(),

    
      physicalAddress: this.fb.group({
        unitNumber: [0],
        unitName: new FormControl(),
        streetAddress: new FormControl(),
        addressLineTwo: new FormControl(),
        postalCode: new FormControl(),
        suburb: new FormControl(),
        province: new FormControl(),
        country: new FormControl()
      }),
    
      medicalAid: this.fb.group({
        medicalAidName: new FormControl(),
        medicalAidNumber: new FormControl(),
        medicalAidMainMember: new FormControl(),
        medicalAidMainMemberFullName: new FormControl(),
        medicalAindMainMemberIdentityNumber: new FormControl(),
        dependentCode: new FormControl(),
        occupation: new FormControl(),
        postalAddress: new FormControl(),
        employer: new FormControl(),
        residentialAddress: new FormControl(),
        phoneNumber: new FormControl()
      }),
    
      patientNextOfKin: this.fb.group({
        fullName: new FormControl(),
        emailAddress: new FormControl(),
        phoneNumber: new FormControl(),
        fullAddress: new FormControl(),
        relationship: new FormControl()
      }),
    
      patientRefDoctor: this.fb.group({
        name: new FormControl(),
        fullName: new FormControl(),
        practiceNumber: new FormControl(),
        emailAddress: new FormControl(),
        fullAddress: new FormControl()
      }),
    
      employmentHistory: this.fb.group({
        employmentHistoryID: new FormControl(),
        current: new FormControl(),
        lastJob: new FormControl(),
        previousJobs: new FormControl(),
        whyLeaveEachJob: new FormControl(),
        hasEverWorked:[false],
        onDGWhy: new FormControl(),
        patientID: new FormControl()
      }),
    
      personalHistory: this.fb.group({
        personalHistoryID: new FormControl(),
        birthAndECD: new FormControl(), // early childhood development
        presentAndPlacement: new FormControl(),
        patientID: new FormControl()
      }),
    
      relationshipHistory: this.fb.group({
        relationshipHistoryID: new FormControl(),
        maritalStatus: new FormControl(),
        numberOfChildren: [0],
        numberOfPartners: [0],
        inARelationship: new FormControl(),
        howLongTested: new FormControl(), // how long has the relationship lasted
        previousRelationships: new FormControl(),
        sexuallyActive: ['false'],
        patientID: new FormControl()
      }),
    
      familyHistory: this.fb.group({
        familyHistoryID: new FormControl(),
        father: new FormControl(),
        mother: new FormControl(),
        brother: new FormControl(),
        sister: new FormControl(),
        others: new FormControl(),
        patientID: new FormControl()
      })
    });
  }
    
    
addNewPatient(){
 
  this.patientService.createPatient(this.createPatientForm.value).subscribe(response=>{
    this.patient=response;
    this.toastService.success('Patient with Id: '+this.patient.patientID+ 'hass been successfully created.', 'Success')
    this.route.navigate(['patients/'+this.patient.patientID])
  },error=>{
    this.toastService.error(error.error);
    
  });
}
}
