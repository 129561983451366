<div class="row container-fluid " >
    
    <main class="col-12 main-row">
      <div class="heading"> 
        <h2>Invoices Records </h2>
      </div>
      <div class="table-responsive">
     <hr>
        <button type="button" class="btn btn-primary" (click)="openModal(template)">Create New Invoice</button>
        <table class="table table-striped table-sm" *ngIf="invoices?.length>0;else elseBlock">
          <thead>
            <tr>
              <th>Invoice ID#</th>
              <th>Invoice Total</th>
              <th>Amount Paid</th>
              <th>Balance</th>
              <th>Patient </th>
              <th>Due Date</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            <tr  *ngFor="let invoice of invoices" 
            >
              <td>{{invoice.invoiceID}}</td>
              <td>{{invoice.invoiceTotal}}</td>
              <td>{{invoice.amountPaid}}</td>
              <td>{{invoice.balance}} </td>
              <td>{{invoice.patient.firstName}}</td>
              <td>{{invoice.dueDate | date}}</td>
              <td><button type="button" class="btn-outline-dark"  (click)='openModalInvice(updateInvoiceTemplate,invoice)'>Edit</button>
                <button (click)="generatePdf(invoice)" class="btn-outline-dark"> Print</button></td>
            
            </tr>
          </tbody>
      </table>
      <ng-template #elseBlock>
        <div class="alert alert-info" role="alert">
         Oops!! No records found.
        </div>

      </ng-template>
      </div>
      <div class="d-flex justify-content-center" *ngIf="paginationInvoice"> <!--Pagination Invoices-->
        <pagination [boundaryLinks]="true"
         [totalItems]="paginationInvoice.totalCount"
         [itemsPerPage]="paginationInvoice.pageSize"
         [(ngModel)]="paginationInvoice.currentPage"
         (pageChanged)="pageChanged($event)"
            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
        </pagination>
      </div>
    
     
    <ng-template #template>
      <div class="modal-header">
        <h4 class="modal-title pull-left">Create Invoice</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="alert alert-info" *ngIf="createInvoiceForm.touched">
          <p><strong>Information: </strong> Changes dected, please not any unsaved changes will be lost!</p>
      </div>
        <form class =" " [formGroup]="createInvoiceForm"  (ngSubmit)='newInvoice()' autocomplete="off"> 
            <div class="mb-3" >
                <label  class="form-label"> Patient </label>
               <span></span>
                <select id="myinvoice" class="form-control" formControlName='patientID' >
                    <option   *ngFor="let patient of patients" [ngValue]="patient.patientID">
                        {{'Patient Id '+patient.patientID +',  Patient: '+ patient.firstName }}
                      
                    </option>
              
                  </select>
              </div>              
          <div class="mb-3">
              <label for="InputEmail1" class="form-label">Due Date</label>
              <input formControlName='dueDate'
              type="date" class="form-control" placeholder="" >
              
            </div>
            <div class="mb-3">
              <label  class="form-label">Amount Paid</label>
              <input formControlName='amountPaid' type="text" class="form-control" placeholder="0">
            </div> 
            <div
            formArrayName="invoiceItems"
            *ngFor="let Item of createInvoiceForm.get('invoiceItems')['controls']; let i = index;"
            >
            <div [formGroupName]="i">
              <div class="form-group mb-3">
                <label for="item" class="form-label">Tariff Code</label>
                <input id="item" formControlName="item" placeholder="Tariff Code" class="form-control">
              </div>
              
              <div class="form-group mb-3">
                <label for="icd_10_Code" class="form-label">ICD 10 Code</label>
                <input id="icd_10_Code" formControlName="icd_10_Code" placeholder="ICD 10 Code" class="form-control">
              </div>
              
              <div class="form-group mb-3">
                <label for="description" class="form-label">Item Description</label>
                <input id="description" formControlName="description" placeholder="Item Description" class="form-control">
              </div>
              
              <div class="form-group mb-3">
                <label for="quantity" class="form-label">Quantity</label>
                <input id="quantity" formControlName="quantity" placeholder="Quantity" class="form-control">
              </div>
              
              <div class="form-group mb-3">
                <label for="unityPrice" class="form-label">Item Price</label>
                <input id="unityPrice" formControlName="unityPrice" placeholder="Item Price" class="form-control">
              </div>
              
              <button type="button" (click)="removeItem(i)">Delete</button>
            </div>
          </div>
          <button type="button" (click)="addItem()">Add Item</button>
      
    
          
         
          <div class="mb-3">
            <label  class="form-label">Terms | Extra Information</label>
            <textarea formControlName='invoiceTerms' class="form-control">                             
            </textarea>
          </div> 
      
          <button *ngIf="createInvoiceForm.dirty" type="submit" class=" btn btn-lg btn-primary" >Submit</button>
      
      </form>
      </div>
    </ng-template> <!--End of Create Invoice template-->
    
    
    <ng-template #updateInvoiceTemplate bsModal  class="modal fade" tabindex="-1"
    role="dialog" aria-labelledby="dialog-sizes-name1" >
     
     
      <div class="modal-header">
        <h4 class="modal-title pull-left">Update Invoice For : {{selectedInvoice.patient.firstName + " " + selectedInvoice.patient.lastName}} </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ">
        <form class =" " [formGroup]="createInvoiceForm"  (ngSubmit)='updateInvoice()' autocomplete="off"> 
                     
          <div class="mb-3">
              <label for="InputEmail1" class="form-label">Dute Date</label>
              <input  name='dueDate' type="date" formControlName='dueDate' [(ngModel)]="selectedInvoice.dueDate"
              class="form-control" placeholder="" >
              
            </div>
           
            
            <div
            formArrayName="invoiceItems"
            *ngFor="let Item of createInvoiceForm.get('invoiceItems')['controls']; let i = index;"
            >
            <div [formGroupName]="i"  >
              <div class="form-group mb-3">
                <label for="item" class="form-label">Tariff Code</label>
                <input id="item" formControlName="item" placeholder="Tariff Code" class="form-control">
              </div>
              
              <div class="form-group mb-3">
                <label for="icd_10_Code" class="form-label">ICD 10 Code</label>
                <input id="icd_10_Code" formControlName="icd_10_Code" placeholder="ICD 10 Code" class="form-control">
              </div>
              
              <div class="form-group mb-3">
                <label for="description" class="form-label">Item Description</label>
                <input id="description" formControlName="description" placeholder="Item Description" class="form-control">
              </div>
              
              <div class="form-group mb-3">
                <label for="quantity" class="form-label">Quantity</label>
                <input id="quantity" formControlName="quantity" placeholder="Quantity" class="form-control">
              </div>
              
              <div class="form-group mb-3">
                <label for="unityPrice" class="form-label">Item Price</label>
                <input id="unityPrice" formControlName="unityPrice" placeholder="Item Price" class="form-control">
              </div>
              
             
              <button type="button" (click)="removeItem(i)">Delete</button>
            </div>
          </div>
          <button type="button" (click)="addItem()">Add Item</button>
        
          <div class="mb-3 mt-5">
            <label  class="form-label">Terms | Extra Information </label>
            <textarea formControlName='invoiceTerms' name='invoiceTerms' [(ngModel)]="selectedInvoice.invoiceTerms" class="form-control">                             
            </textarea>
          </div> 
      
          <button type="submit" class=" btn btn-lg btn-primary" >Update</button>
      
      </form>
      
      </div>
    
    </ng-template>
    </main>
</div>

